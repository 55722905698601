import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

export const unsubscribeSoloUser = async () => {
	try {
		const mutation = gql`
			mutation {
				RemoveSoloLicence
			}
		`;
		const {
			data: { RemoveSoloLicence }
		} = await apollo.mutate({
			mutation,
			fetchPolicy: 'no-cache'
		});

		return RemoveSoloLicence;
	} catch (e) {
		console.log(e.message);
	}
};

export const getLicenses = async () => {
	const {
		data: { GetLicenses }
	} = await apollo.query({
		query: gql`
			query {
				GetLicenses {
					id
					createdAt
					companyName
					companyId
					userId
					license
					description
					createdBy
					creator {
						id
						name
						firstName
					}
					user {
						id
						name
						firstName
						email
					}
					company {
						id
						name
						vat
						phone
						info
						contactName
						contactFunction
					}
				}
			}
		`,
		fetchPolicy: 'no-cache'
	});

	return GetLicenses;
};

export const deleteLicense = async (LicenseId) => {
	const mutation = gql`
		mutation ($LicenseId: ID!) {
			DelLicense(LicenseId: $LicenseId) {
				id
			}
		}
	`;

	const {
		data: { DelLicense }
	} = await apollo.mutate({
		mutation,
		variables: { LicenseId },
		fetchPolicy: 'no-cache'
	});

	return DelLicense;
};

export const newLicence = async () => {
	const mutation = gql`
		mutation {
			NewLicense {
				${columnLicense}
			}
		}
	`;

	const {
		data: { NewLicense }
	} = await apollo.mutate({
		mutation,
		variables: {}
	});

	return NewLicense;
};

export const getLicenseWithId = async (LicenseId) => {
	const query = gql`
		query ($LicenseId: ID!) {
			GetLicenseWithId(LicenseId: $LicenseId) {
				userLanguage
				${columnLicense}
			}
		}
	`;

	const {
		data: { GetLicenseWithId }
	} = await apollo.query({
		query,
		variables: {
			LicenseId
		},
		fetchPolicy: 'network-only'
	});

	return GetLicenseWithId;
};

export const updLicense = async (LicenseId, UpdatedLicense) => {
	const mutation = gql`
		mutation ($LicenseId: ID!, $UpdatedLicense: LicenseInput!) {
			UpdLicense(LicenseId: $LicenseId, UpdatedLicense: $UpdatedLicense) {
				userLanguage
				${columnLicense}
			}
		}
	`;

	const {
		data: { UpdLicense }
	} = await apollo.mutate({
		mutation,
		variables: {
			LicenseId,
			UpdatedLicense
		}
	});

	return UpdLicense;
};

const columnLicense = `
	id
	createdAt
	companyName
	companyId
	userId
	license
	description
	createdBy
	creator {
		id
		name
		firstName
	}
	user {
		id
		name
		firstName
		email
	}
	company {
		id
		name
		vat
		phone
		info
		contactName
		contactFunction
	}
`;
