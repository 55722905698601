<style scoped>
.card-body >>> table > tbody > tr > td {
	cursor: pointer;
}

input[type='texte'] {
	width: 100%;
	padding: 12px 20px;
	margin: 4px 0;
	box-sizing: border-box;
	border: none;
	background-color: white;
	color: white;
}
.btn1 {
	margin-bottom: 10px;
	width: 250px;
}
</style>

<template>
	<div>
		<errorContainer :error="erreur" :warning="warning">
			<b-row v-if="Licenses">
				<b-col md="12">
					<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">
						{{ this.caption4 }}
					</h1>
				</b-col>
				<b-col cols="12" xl="12">
					<transition name="slide">
						<b-card no-body>
							<b-card-body>
								<b-row>
									<b-col cols="6" class="my-1" v-if="$screen.width >= 992">
										<b-form-group
											:label="FormMSG(1, 'Filter')"
											label-cols="3"
											label-align-sm="left"
											label-size="sm"
											label-for="filterInput"
											class="mb-0"
										>
											<b-input-group size="sm">
												<b-form-input
													v-model="filter"
													type="search"
													id="filterInput"
													:placeholder="FormMSG(20, 'Type to Search')"
												></b-form-input>
												<b-input-group-append>
													<b-button :disabled="!filter" @click="filter = ''">
														<i class="fa fa-times" aria-hidden="true"></i>
													</b-button>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col :cols="`${$screen.width >= 992 ? 6 : 12}`">
										<b-form-group :class="`${$screen.md ? 'pull-right' : ''}`">
											<b-button
												:block="!$screen.md"
												v-html="FormMSG(2, 'New license')"
												@click="newLicense()"
												size="lg"
												variant="primary"
											></b-button>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col cols="12" xl="12">
										<CardListBuilder v-if="$screen.width < 992" :items="Licenses" :fields="accLicenses">
											<template slot="actions" slot-scope="data">
												<b-button variant="none" size="sm" @click="editLicense(data.item)">
													<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
												</b-button>

												<b-button variant="none" size="sm" @click="delItem(data.item.id)">
													<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
												</b-button>
											</template>
										</CardListBuilder>
										<b-table
											v-if="$screen.width >= 992"
											:hover="hover"
											responsive="sm"
											ref="myTable"
											sticky-header="700px"
											selectable
											:selectedVariant="selectedColor"
											:select-mode="selectMode"
											:items="Licenses"
											style="text-align: left"
											:fields="accLicenses"
											:current-page="currentPage"
											:filter="filter"
											:per-page="perPage"
											bordered
											small
											:head-variant="hv"
										>
											<!-- <template slot="view" slot-scope="data">  @row-clicked="rowClicked" -->
											<template v-slot:cell(view)="data">
												<b-button variant="none" size="sm" @click="editLicense(data.item)">
													<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
												</b-button>
											</template>
											<!-- <template slot="rem" slot-scope="data"> -->
											<template v-slot:cell(rem)="data">
												<b-button variant="none" size="sm" @click="delItem(data.item.id)">
													<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
												</b-button>
											</template>
										</b-table>
									</b-col>
								</b-row>
								<b-modal
									header-class="header-class-modal-doc-package"
									:title="FormMSG(60, 'DELETE ?')"
									class="modal-success"
									v-model="successModal"
									@ok="delItemConfirmed()"
									ok-variant="Delete"
								>
									{{ this.FormMSG(61, 'Are you sure you want to delete this item?') }}
								</b-modal>
								<loading :active.sync="isLoading" :is-full-page="true"></loading>
								<!-- <nav v-if="this.Licenses.length > this.perPage && $screen.width >= 992">
									<b-row>
										<b-col xl="5">
											<b-pagination
												size="sm"
												:total-rows="getRowCount(this.Licenses)"
												:per-page="perPage"
												v-model="currentPage"
												prev-text="<-"
												next-text="->"
												hide-goto-end-buttons
											/>
										</b-col>
									</b-row>
								</nav> -->
							</b-card-body>
						</b-card>
					</transition>
				</b-col>
			</b-row>
		</errorContainer>
	</div>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';

import Loading from 'vue-loading-overlay';
import { isNil, objReducer } from '@/shared/utils';
import SupplierSelector from '@/components/SupplierSelector';

import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
const axios = require('axios').create();
import { getLicenses, newLicence, deleteLicense } from '@/cruds/license.crud';

export default {
	name: 'LicenseList',
	mixins: [languageMessages, globalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Loading,
		SupplierSelector
	},
	data: () => {
		//const tt = this.FormMSG;
		return {
			hv: 'dark',
			erreur: {},
			formDataObj: {},
			Licenses: [],
			Dlvs: [],
			curLic: {},
			newLic: {},
			currentPage: 1,
			currentIdx: 0,
			manualClick: true,
			stopPropagation: '',
			nativeEvent: '',
			perPage: 0,
			warning: '',
			filter: '',
			isLoading: false,
			pdfFile: null,
			newCreated: 0,
			selectMode: 'single',
			selectedColor: 'primary',
			successModal: false,
			successModalDelete: false,
			licenseIdToDelete: 0,
			firstTime: true,
			editData: {
				type: Object,
				required: false,
				default: null
			}
			//  bob : tt(10,"testbob"),
		};
	},
	created() {
		this.reloadData();
	},
	computed: {
		accLicenses() {
			return [
				{
					key: 'id',
					label: this.FormMSG(3, 'Id'),
					sortable: true
				},
				{
					key: 'license',
					label: this.FormMSG(4, 'License'),
					sortable: true
				},
				{
					key: 'companyName',
					label: this.FormMSG(14, 'Company'),
					sortable: true
				},
				{
					key: 'fullName',
					label: this.FormMSG(5, 'Name'),
					sortable: true
				},
				{
					key: 'user.email',
					label: this.FormMSG(15, 'Email'),
					sortable: true
				},
				{
					key: 'createdAt',
					label: this.FormMSG(6, 'Created On'),
					formatter: (value) => {
						return (
							value.split('T')[0].substring(8) +
							'/' +
							value.split('T')[0].substring(5, 7) +
							' ' +
							value.split('T')[1].substring(0, 2) +
							':' +
							value.split('T')[1].substring(3, 5)
						);
					},
					sortable: true
				},
				{
					key: 'view',
					label: 'Edit',
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				},
				{
					key: 'rem',
					label: this.FormMSG(7, 'Delete'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		},
		caption1() {
			return this.FormMSG(8, 'Licenses');
		},
		caption4() {
			return this.FormMSG(9, 'List of all licenses');
		}
	},
	methods: {
		editLicense(item) {
			var idStr = item.id.toString();
			const tsLink = '/licenseList/' + idStr;
			//console.log("link:",tsLink);
			this.$router.push({ path: tsLink });
		},
		initValuesForNoRecords() {
			this.newLic.fullName = 'None';
			this.newLic.license = ' NEW License';
			this.newLic.id = 0;
			this.newLic.companyName = 'ND';
			this.newLic.companyId = 0;
			this.newLic.userId = 0;
		},
		async reloadData() {
			try {
				this.Licenses = await getLicenses();

				for (var i = 0; i < this.Licenses.length; i++) {
					this.Licenses[i].fullName = this.Licenses[i].user.name + ' ' + this.Licenses[i].user.firstName;
				}
				if (!isNil(store.state.curLicense)) {
					// find and select the newly created
					for (var i = 0; i < this.Licenses.length; i++) {
						if (this.Licenses[i].id == store.state.curLicense.id) {
							this.curLic = {
								...this.Licenses[i]
							};
							this.newLic = Object.assign({}, this.curLic);
							this.newLic = {
								...this.Licenses[i]
							};
							this.clickRow(i);
							break;
						}
					}
				} else {
					// select element
					if (this.Licenses.length > 0) {
						this.curLic = this.Licenses[0];
						this.newLic = Object.assign({}, this.curLic);

						//console.log("reload data clickrow 0");
						var idx = store.getCurrentLicenseTableIndex();
						//console.log("var idx = store.state.getCurrentAccomodationTableIndex", idx);
						this.clickRow(idx);
						this.firstTime = false;
					} else {
						//console.log("in reload data initValuesForNoRecords");
						this.initValuesForNoRecords();
						//console.log("end initValuesForNoRecords:", this.newLic);
					}
				}
			} catch (error) {
				console.log('catch error in reloadData:', error);
			}
		},
		delItem(id) {
			this.licenseIdToDelete = parseInt(id, 10);
			this.successModal = true;
		},
		async delItemConfirmed() {
			var LicenseId = parseInt(this.licenseIdToDelete, 10);
			try {
				await deleteLicense(LicenseId);
				this.reloadData();
			} catch (error) {
				console.log(error);
				this.erreur = error;
			}
		},
		getRowCount(items) {
			//console.log(items)
			return items.length;
		},
		rowClicked(item, index) {
			this.currentIdx = (this.currentPage - 1) * this.perPage + index;

			store.setCurrentLicenseTableIndex(this.currentIdx);

			this.curLic = item;

			this.newLic = Object.assign({}, item);

			store.state.curLicense = this.newLic;
			//console.log("this.manual:",this.manualClick);
			if (this.manualClick == true) {
				this.editLicense(item);
			} else {
				this.manualClick = true;
			}
		},
		async newLicense() {
			try {
				const NewLicense = await newLicence();
				store.state.curLicence = NewLicense;
				// go to edit window
				this.editLicense(store.state.curLicence);
			} catch (error) {
				console.log(error);
				this.erreur = error;
			}
		},
		clickRow(idx) {
			//console.log("inclickrow");
			this.manualClick = false;
			this.currentPage = 1;
			//    parseInt(parseInt(idx, 10) / parseInt(this.perPage, 10), 10) + 1;
			store.setCurrentDocumentTableIndex(idx);
			this.$nextTick(function () {
				// Code that will run only after the
				// entire view has been re-rendered
				// found in https://jsfiddle.net/sem409uc/
				if (!isNil(this.$refs.myTable)) {
					let myTable = this.$refs.myTable.$el,
						tableBody = myTable.getElementsByTagName('tbody')[0],
						tableRows = tableBody.getElementsByTagName('tr');
					tableRows[idx].click();
				}
			});
		}
	}
};
</script>
